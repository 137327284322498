import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import Switch from "react-switch";
import close from "../imgs/icons/close.svg";
import userPurple from "../imgs/icons/userPurple.svg";
import residentiPurple from "../imgs/icons/residentiPurple.svg";
import delet from '../imgs/icons/delete.svg';
import add from '../imgs/icons/add.svg';
import addW from '../imgs/icons/addW.svg';
import confirmW from '../imgs/icons/confirmW.svg';
import modifica from '../imgs/icons/modifica.svg';
import arrowPurple from '../imgs/icons/arrowPurple.svg';
import del from '../imgs/icons/delete.svg';
import save from '../imgs/icons/save.svg';
import carica from '../imgs/icons/carica.svg';

import stanzeNode from "node/stanze/stanze.js";
import sensoriNode from "node/sensori/sensori.js";
import residentiNode from "node/residenti/residenti.js";
import strutturaNode from "node/struttura/struttura.js";
import magazzinoNode from 'node/magazzino/magazzino.js';
import allarmiNode from "node/allarmi/allarmi.js";
import profiliNode from "node/profili/profili.js";

import lang from "../lang.json";
import { all } from "axios";
const langSet = lang[localStorage.getItem("lang")].scheda_residente;

const SchedaResidente = (props) => {
    const [mod, setMod] = useState(0);
    const [nome, setNome] = useState();
    const [cognome, setCognome] = useState();
    const [id, setId] = useState();
    const [registrazione, setRegistrazione] = useState();
    const [status, setStatus] = useState(0);
    const [stanze, setStanze] = useState([]);
    const [camera, setCamera] = useState();
    const [sensori, setSensori] = useState([]);
    const [sensoriSel, setSensoriSel] = useState([]);
    const [sensoriDel, setSensoriDel] = useState([]);
    const [sensoriRes, setSensoriRes] = useState([]);
    const [tipoSensori, setTipoSensori] = useState([]);
    const [descrizione, setDescrizione] = useState("");
    const [inizio, setInizio] = useState("00:00");
    const [fine, setFine] = useState("00:00");
    const [alarms, setAlarms] = useState([]);
    const [allarmiLetto, setAlarmiLetto] = useState([]);
    const [allarmiLettoMod, setAllarmiLettoMod] = useState([]);
    const [allarmiCaduta, setAllarmiCaduta] = useState([]);
    const [allarmiCadutaDel, setAllarmiCadutaDel] = useState([]);
    const [allarmiCadutaMod, setAllarmiCadutaMod] = useState([]);
    const [allarmiDel, setAllarmiDel] = useState([]);
    const [allarmiSel, setAllarmiSel] = useState([]);
    const [alarmSel, setAlarmSel] = useState(null);
    const [struttura, setStruttura] = useState([]);
    const [stanzares, setStanzRes] = useState();
    const [geoAllarms, setGeoAllarms] = useState([]);
    const [selGeoAllarmsDel, setSelGeoAllarmsDel] = useState([]);
    const [enablemod, setenablemod] = useState(false);
    const [allarmiassenzastanzadelDel, setAllarmiassenzastanzadelDel] = useState([]);
    const [operation, setOperation] = useState();
    const [allarmiassenzastanza, setallarmiassenzastanza] = useState([]);
    const [allarmiAssenzastanzaDel, setAllarmiAssenzastanzaDel] = useState([]);
    const [allarmiAssenzastanzaMod, setAllarmiAssenzastanzaMod] = useState([]);

    const [profili, setProfili] = useState([]);
    const [idProfilo, setIdProfilo] = useState();

    const [editPopup, setEditPopup] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
const filteredOptions = sensori
  ? sensori
      .filter((sensore) => {
        // console.log(sensore);
        const sensoreText = `${sensore.nome} - ${sensore.tipo}`;
        return sensoreText.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map((sensore) => ({
        value: sensore.id,
        label: `${sensore.nome} - ${sensore.tipo}`,
      }))
  : [];
    useEffect(() => {
        
        setSensoriSel([]);
        setSensoriDel([]);
    }, [props]);

    useEffect(() => { // carica i dati della struttura
        console.log(accountData);
        setMod(0);
        setStatus(0);
        setNome(props.residente.nome ?? '');
        setCognome(props.residente.cognome ?? '');
        setId(props.residente.id ?? '');
        setRegistrazione(props.residente.registrazione ?? '');
        setCamera(props.residente.stanza ?? '');
        setStanzRes(props.residente.stanza ?? '');
        setSensoriRes([]);
        setTipoSensori([]);
        getSensoriRes();
    }, [props.residente]);

    const sort = "id";
    const order = "asc";
    const accountData = JSON.parse(localStorage.getItem("account"));
    const scrollRef = useRef(null);

    const selettoreAllarmiLetto = ['Nessun allarme', 'Allarme disabilitato', 'Allarme immediato', 'Allarme ritardato'];
    const selettoreAllarmiassenzastanza = ['Nessun allarme', 'Allarme disabilitato', 'Allarme immediato', 'Allarme ritardato'];
    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";
    const defDot = "dot red medium normPosition";
    
    const selDot = "dot green medium normPosition";

    function getzone(id_thinkin, idzone) { // carica le zone
        if (alarms[alarmSel] && "valore" in alarms[alarmSel]) {
            if (alarms[alarmSel].valore.includes(id_thinkin)) {
                return selDot
            } else {
                return defDot;
            }
        } else {
            return defDot;
        }
    }
    

    async function getallarmiassenzastanza() {
        props.loading(true);
        let id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore;
        console.log(id_sensore)
        allarmiNode.getAllarmiOccupazioneStanza(accountData.token, id_sensore).then((res) => {
            setallarmiassenzastanza(res.data);
            console.log(res.data);
        }).finally(() => {
            props.loading(false);
        });
    }

    async function allarmiassenzastanzaSwitch(id, mode, stato) {
        try {
            props.loading(true);
            switch (mode) {
                case 'add':
                    setallarmiassenzastanza([...allarmiassenzastanza, { descrizione: '', stato: '0', inizio: '', fine: '' }]);
                    break;
                case 'edit':

                    let indexEdit = allarmiAssenzastanzaMod.findIndex((x) => x.id === id);
                    if (indexEdit === -1) {
                        setAllarmiAssenzastanzaMod([...allarmiAssenzastanzaMod, { id: id, stato: stato }]);
                    } else {
                        allarmiAssenzastanzaMod[indexEdit].stato = stato;
                        setAllarmiAssenzastanzaMod([...allarmiAssenzastanzaMod]);
                    }
                    break;
                case 'del':



                    let indexAllarme = allarmiassenzastanza.findIndex((x) => x.id === id);

                    // allarmiassenzastanza[x].stato = "Nessun allarme";

                    // indexAllarme.stato = "Nessun allarme";

                    // console.log(indexAllarme);

                    if (indexAllarme !== -1) {
                        setAllarmiassenzastanzadelDel([...allarmiAssenzastanzaDel, allarmiassenzastanza[indexAllarme].id]);
                        allarmiassenzastanza.splice(indexAllarme, 1);
                        setallarmiassenzastanza([...allarmiassenzastanza]);
                    }
                    break;
                default:
                    break;
            }
            props.loading(false);
            return;
        } catch (e) {
            console.log(e);
            return;
        }
    }

    function scroll(dir) { // scroll della lista
        const item = scrollRef.current;
        const distance = 150;
        const duration = 350;
        const increment = distance / duration * 10;
        let counter = 0;
        let intervalId;
        if (dir === "left") {
            intervalId = setInterval(() => {
                item.scrollBy(-increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        } else {
            intervalId = setInterval(() => {
                item.scrollBy(increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        }
    }
    function getInfoGeoAllarm(i) { // carica le info dell'allarme
        setInizio(alarms[i].inizio)
        setFine(alarms[i].fine)
        setDescrizione(alarms[i].descrizione)
    }

    async function salvaModifiche(what) { // salva le modifiche
        try {
            let response;
            props.loading(true);
            switch (what) {
                case "sensori": // salva i sensori
                    if (sensoriSel.length > 0) {
                        for (let sensSel in sensoriSel) {
                            if (sensoriSel[sensSel] !== undefined) {
                                response = await residentiNode.patchSensoreResidente(accountData.token, props.residente.id, sensoriSel[sensSel].id);
                                if (!response.result) throw response.error;
                            }

                        }
                    }
                    //! MODIFICATO DA SIMO, NON ELIMINARE IN MERGE GRAZIE
                    if (sensoriDel.length > 0) {
                        for (let sensDel in sensoriDel) {
                            if (sensoriDel[sensDel] !== undefined) {
                                response = await residentiNode.patchSensoreResidente(accountData.token, props.residente.id, sensoriDel[sensDel].id_sensore ?? sensoriDel[sensDel].id);
                                if (!response.result) throw response.error;
                            }
                        }
                    }

                    await getSensoriRes();
                    break;

                case "deleteAllarmi": // elimina gli allarmi
                    if (allarmiDel.length > 0) {
                        for (let i = 0; i < allarmiSel.length; i++) {
                            if (allarmiDel[i] !== undefined) {
                                response = await allarmiNode.deleteAllarme(accountData.token, allarmiDel[i]);
                                if (!response.result) throw response.error;
                            }
                        }
                    }
                    break;

                case "stanza":  // salva la stanza
                    response = await residentiNode.patchStanzaResidente(accountData.token, id, camera);

                    if (!response.result) throw response.error;
                    break;
                case "residente": // salva il residente
                    response = await residentiNode.patchSchedaResidente(accountData.token, props.residente.id, nome, cognome);
                    if (!response.result) throw response.error;
                    break;
                case "nomeSensore": // salva il nome del sensore
                    response = await sensoriNode.patchNomeSensore(accountData.token, props.sensore.id, nome);
                    await getSensoriRes();
                    if (!response.result) throw response.error;
                    break;
                case 'allarmiLetto': // salva gli allarmi del letto
                    //?/* -------------------- FUNZIONE PER ELIMINAZIONE ALLARMI LETTO -------------------- */
                    console.log(allarmiDel);
                    for (let allarme_id of allarmiDel) {
                        if (allarme_id === undefined) continue;
                        response = await allarmiNode.deleteAllarme(accountData.token, allarme_id);
                    }

                    //?/* -------------------- RECUPERO ID SENSORE LETTO -------------------- */

                    var id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore letto")[0].id_sensore;

                    //?/* -------------------- FUNZIONE PER AGGIUNTA ALLARMI LETTO -------------------- */
                    var nuoviAllarmi = [];

                    for (let allarme of allarmiLetto) {

                        if (allarme.id_vuoto || allarme.id_occupato) continue; // se l'allarme è già presente nel db non lo aggiungo

                        if (allarme.letto_occupato === 'Nessun allarme' && allarme.letto_vuoto === 'Nessun allarme') continue; // se l'allarme non è attivo non lo aggiungo

                        if (allarme.id_occupato === undefined && allarme.letto_occupato !== 'Nessun allarme') { // se l'allarme non è presente nel db lo aggiungo
                            let allarme_occupato = { descrizione: allarme.descrizione, giorni: [0, 1, 2, 3, 4, 5, 6], inizio: allarme.inizio, fine: allarme.fine, id_piani: [props.residente.percorso.split('|')[0].split(' ')[0]], tipo: 'allarme', lingua: 'it' }; // creo l'allarme

                            switch (allarme.letto_occupato) { // imposto i valori dell'allarme
                                case 'Allarme disabilitato': // allarme disabilitato
                                    allarme_occupato.valore = { belegt: 1 };
                                    allarme_occupato.abilitato = false;
                                    allarme_occupato.messaggio = 'Il letto è occupato';
                                    allarme_occupato.ritardo = false;
                                    break;
                                case 'Allarme immediato': // allarme immediato
                                    allarme_occupato.valore = { belegt: 1 };
                                    allarme_occupato.abilitato = true;
                                    allarme_occupato.messaggio = 'Il letto è occupato';
                                    allarme_occupato.ritardo = false;
                                    break;
                                case 'Allarme ritardato': // allarme ritardato
                                    allarme_occupato.valore = { belegt: 1 };
                                    allarme_occupato.abilitato = true;
                                    allarme_occupato.messaggio = 'Il letto è occupato';
                                    allarme_occupato.ritardo = true;
                                    break;
                                default:
                                    break;
                            }

                            allarme_occupato.id_sensore = id_sensore;

                            nuoviAllarmi.push(allarme_occupato);
                        }

                        if (allarme.id_vuoto === undefined && allarme.letto_vuoto !== 'Nessun allarme') { // se l'allarme non è presente nel db lo aggiungo
                            let allarme_vuoto = { descrizione: allarme.descrizione, giorni: [0, 1, 2, 3, 4, 5, 6], inizio: allarme.inizio, fine: allarme.fine, id_piani: [props.residente.percorso.split('|')[0].split(' ')[0]], tipo: 'allarme', lingua: 'it' };

                            switch (allarme.letto_vuoto) {
                                case 'Allarme disabilitato':
                                    allarme_vuoto.valore = { belegt: 0 };
                                    allarme_vuoto.abilitato = false;
                                    allarme_vuoto.messaggio = 'Il letto è vuoto';
                                    allarme_vuoto.ritardo = false;
                                    break;
                                case 'Allarme immediato':
                                    allarme_vuoto.valore = { belegt: 0 };
                                    allarme_vuoto.abilitato = true;
                                    allarme_vuoto.messaggio = 'Il letto è vuoto';
                                    allarme_vuoto.ritardo = false;
                                    break;
                                case 'Allarme ritardato':
                                    allarme_vuoto.valore = { belegt: 0 };
                                    allarme_vuoto.abilitato = true;
                                    allarme_vuoto.messaggio = 'Il letto è vuoto';
                                    allarme_vuoto.ritardo = true;
                                    break;
                                default:
                                    break;
                            }

                            allarme_vuoto.id_sensore = id_sensore;

                            nuoviAllarmi.push(allarme_vuoto);
                        }
                    }

                    if (nuoviAllarmi.length > 0) response = await allarmiNode.addAllarme(accountData.token, nuoviAllarmi);

                    //?/* -------------------- FUNZIONE PER MODIFICA ALLARMI LETTO -------------------- */
                    for (let allarmeMod of allarmiLettoMod) {
                        response = await allarmiNode.patchStatoAllarme(accountData.token, allarmeMod.id, allarmeMod.stato);
                    }


                    await getAllarmiLetto();

                    break;
                case 'allarmiCaduta':
                    //TODO/* -------------------- FUNZIONE PER ELIMINAZIONE ALLARMI LETTO -------------------- */
                    for (let allarme_id of allarmiCadutaDel) {
                        // if (allarmiLetto ==  undefined) continue;
                        //
                        // if ()

                        if (allarme_id === undefined) continue;
                        if (allarme_id !== undefined) response = await allarmiNode.deleteAllarme(accountData.token, allarme_id);
                    }

                    //?/* -------------------- RECUPERO ID SENSORE LETTO -------------------- */

                    var id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore caduta")[0].id_sensore;

                    //?/* -------------------- FUNZIONE PER AGGIUNTA ALLARMI LETTO -------------------- */
                    var nuoviAllarmi = [];

                    for (let allarme of allarmiCaduta) {
                        if (allarme.id || allarme.stato === 'Nessun allarme') continue;

                        allarme.id_sensore = id_sensore;
                        allarme.giorni = [0, 1, 2, 3, 4, 5, 6];
                        allarme.id_piani = [props.residente.percorso.split('|')[0].split(' ')[0]];
                        allarme.tipo = 'allarme';
                        allarme.lingua = 'it';

                        switch (allarme.stato) {
                            case 'Allarme disabilitato':
                                allarme.valore = { status: 'fall_detected' };
                                allarme.abilitato = false;
                                allarme.messaggio = 'Il paziente è caduto';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme immediato':
                                allarme.valore = { status: 'fall_detected' };
                                allarme.abilitato = true;
                                allarme.messaggio = 'Il paziente è caduto';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme ritardato':
                                allarme.valore = { status: 'fall_detected' };
                                allarme.abilitato = true;
                                allarme.messaggio = 'Il paziente è caduto';
                                allarme.ritardo = true;
                                break;
                            default:
                                break;
                        }

                        nuoviAllarmi.push(allarme);
                    }
                    
                    if (nuoviAllarmi.length > 0) response = await allarmiNode.addAllarme(accountData.token, nuoviAllarmi);

                    //?/* -------------------- FUNZIONE PER MODIFICA ALLARMI LETTO -------------------- */
                    for (let allarmeMod of allarmiCadutaMod) {
                        response = await allarmiNode.patchStatoAllarme(accountData.token, allarmeMod.id, allarmeMod.stato);
                    }

                    await getAllarmiCaduta();
                    getAllarmiCaduta();
                    break;
                case 'allarmiGeo':
                    let addallarm = [];
                    for (let key in alarms) {
                        //per ogni allarme cerca se è presente in alarms

                        if ("id" in alarms[key]) {
                            //se è presente aggiorna lo stato
                            //.log("aggiorno")
                            let response = await allarmiNode.editAllarme(accountData.token, alarms[key]);
                            console.log(response);
                        } else {
                            alarms[key].id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore geofencing")[0].id_sensore;
                            alarms[key].id_piani = [props.residente.percorso.split('|')[0].split(' ')[0]];
                            alarms[key].messaggio = 'Il paziente si trova in';
                            alarms[key].lingua = 'it';
                            addallarm.push(alarms[key])
                            console.log("aggiungo")

                        }
                    }
                    if (addallarm.length > 0) {
                        let response = await allarmiNode.addAllarme(accountData.token, addallarm);
                        console.log(response);
                    }
                    //rimuovi allarmi presenti in selGeoAllarmsDel se sono presenti
                    if (selGeoAllarmsDel.length > 0) {
                        for (let key in selGeoAllarmsDel) {
                            let response = await allarmiNode.deleteAllarme(accountData.token, selGeoAllarmsDel[key].id);
                            console.log(response);
                        }
                    }

                    break;
                case 'allarmipresenzaStanza':
                    //TODO/* -------------------- FUNZIONE PER ELIMINAZIONE ALLARMI LETTO -------------------- */
                    for (let allarme_id of allarmiAssenzastanzaDel) {
                        if (allarme_id === undefined) continue;
                        if (allarme_id !== undefined) response = await allarmiNode.deleteAllarme(accountData.token, allarme_id);
                    }

                    //?/* -------------------- RECUPERO ID SENSORE LETTO -------------------- */

                    var id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore;
                    console.log(sensoriRes.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore)

                    //?/* -------------------- FUNZIONE PER AGGIUNTA ALLARMI LETTO -------------------- */
                    var nuoviAllarmi = [];

                    for (let allarme of allarmiassenzastanza) {
                        if (allarme.id || allarme.stato === 'Nessun allarme') continue;

                        allarme.id_sensore = id_sensore;
                        allarme.giorni = [0, 1, 2, 3, 4, 5, 6];
                        allarme.id_piani = [props.residente.percorso.split('|')[0].split(' ')[0]];
                        allarme.tipo = 'allarme';
                        allarme.lingua = 'it';

                        switch (allarme.stato) {
                            case 'Allarme disabilitato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = false;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme immediato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = true;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme ritardato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = true;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = true;
                                break;
                            default:
                                break;
                        }

                        nuoviAllarmi.push(allarme);
                        console.log(allarme)
                    }
                    getallarmiassenzastanza();
                    
                    if (nuoviAllarmi.length > 0) response = await allarmiNode.addAllarme(accountData.token, nuoviAllarmi);
                    //?/* -------------------- FUNZIONE PER MODIFICA ALLARMI LETTO -------------------- */
                    for (let allarmeMod of allarmiAssenzastanzaMod) {
                        response = await allarmiNode.patchStatoAllarme(accountData.token, allarmeMod.id, allarmeMod.stato);
                    }

                    await getallarmiassenzastanza();
                    break;
                default:
                    break;
            }

            props.getRes();
            setMod(0);
            props.loading(false);
            setAlarmSel(null);
            setSensoriDel([]);
            setSensoriSel([]);
            setAllarmiDel([]);
            setAllarmiSel([]);
            setAllarmiCadutaDel([]);
            setAllarmiCadutaMod([]);
            setAllarmiCaduta([]);
            setAllarmiAssenzastanzaDel([]);
            setAllarmiAssenzastanzaMod([]);
            setallarmiassenzastanza([]);
            

        } catch (e) {
            props.loading(false);
            console.log(e);
        }

    }


    async function getAllarmiGeo() { //funzione per recuperare gli allarmi geofencing
        let id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore geofencing")[0].id_sensore;
        console.log(id_sensore)
        await allarmiNode.getAllarmi(accountData.token, id_sensore).then((res) => {
            setAlarms(res.data);
            setGeoAllarms(res.data);
        });
    }

    async function getLibere() { //funzione per recuperare le stanze libere
        await stanzeNode.getStanzeLibere(accountData.token).then((res) => {
            setStanze(res.data);
        });
    }

    async function getSensoriRes() { //funzione per recuperare i sensori del residente
        props.loading(true);
        await residentiNode.getSensoriResidente(accountData.token, props.residente.id).then((res) => {
            setSensoriRes(res.data);
            let temp = [];
            for (let i = 0; i < res.data.length; i++) { //recupera i tipi di sensori
                temp.push(res.data[i].tipo);
            }
            setTipoSensori(temp);
        }).finally(() => props.loading(false));
    }

    async function getSensori() { //funzione per recuperare i sensori
        await magazzinoNode.getMagazzinoStruttura(accountData.token, sort, order).then((res) => {
            setSensori(res.data);
        });
    }

    async function getStruttura() { //funzione per recuperare la struttura
        await strutturaNode.getStrutturaAll(accountData.token).then((res) => {
            setStruttura(res.data);
        }).finally(
            strutturaNode.getStrutturaAll(accountData.token).then((res) => {
                setStruttura(res.data);
            })
        );
    }

    async function getPianoRes() { //funzione per recuperare il piano del residente
        //trova il piano apaprtenente a camera
        let id_piano = props.residente.percorso.split('|')[0];
        console.log("id_piano", id_piano)
        return id_piano;
    }

    function Alarm(descrizione, inizio, fine, tipo, valore, id_piano, ritardo) { //funzione per aggiungere un allarme
        setAlarms([...alarms, { "descrizione": descrizione, "giorni": [0, 1, 2, 3, 4, 5, 6, 7], "inizio": inizio, "fine": fine, "abilitato": true, "tipo": tipo, "valore": valore, "id_piano": id_piano, "ritardo": ritardo }]);
        if (tipo === "Sensore geofencing") openMiniPopup();
    }


    //? ---------- FUNZIONE PER RICEVERE GLI ALLARMI LETTO
    async function getAllarmiLetto() { //funzione per recuperare gli allarmi letto
        props.loading(true);
        let id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore letto")[0].id_sensore;
        await allarmiNode.getAllarmiLetto(accountData.token, id_sensore).then((res) => {
            setAlarmiLetto(res.data);
            console.log(res.data);
        }).finally(() => {
            props.loading(false);
        });
    }

    async function alarmsList(id_vuoto, id_occupato, mode, id_edit, stato) { //funzione per aggiungere, modificare o eliminare un allarme letto
        try {
            props.loading(true);
            switch (mode) {
                case 'add': //? ---------- AGGIUNGI ALLARME LETTO
                    setAlarmiLetto([...allarmiLetto, { descrizione: '', letto_occupato: '', letto_vuoto: '', inizio: '', fine: '' }]);
                    break;
                case 'edit': //? ---------- MODIFICA ALLARME LETTO
                    let indexEdit = allarmiLettoMod.findIndex((x) => x.id === id_edit);
                    if (indexEdit === -1) {
                        setAllarmiLettoMod([...allarmiLettoMod, { id: id_edit, stato: stato }]);
                    } else {
                        allarmiLettoMod[indexEdit].stato = stato;
                        setAllarmiLettoMod([...allarmiLettoMod]);
                    }
                    break;
                case 'del': //? ---------- ELIMINA ALLARME LETTO
                    console.log("id_vuoto", id_vuoto);
                    console.log("id_occupato", id_occupato);
                    for (let [index, allarme] of allarmiLetto.entries()) {
                        if (id_vuoto !== undefined && allarme.id_vuoto === id_vuoto && id_occupato !== undefined && allarme.id_occupato === id_occupato) {
                            allarmiLetto.splice(index, 1);
                            setAllarmiDel([...allarmiDel, id_vuoto, id_occupato]);
                        } else if (id_vuoto !== undefined && allarme.id_vuoto === id_vuoto && allarme.id_occupato === undefined) {
                            allarmiLetto.splice(index, 1);
                            setAllarmiDel([...allarmiDel, id_vuoto]);
                        } else if (id_occupato !== undefined && allarme.id_occupato === id_occupato && allarme.id_vuoto === undefined) {
                            allarmiLetto.splice(index, 1);
                            setAllarmiDel([...allarmiDel, id_occupato]);
                        }
                        setAlarmiLetto([...allarmiLetto]);
                    }
                    break;
                default: //? ---------- DEFAULT
                    break;
            }
            props.loading(false);
            return;
        } catch (e) {
            console.log(e);
            return;
        }
    }

    async function getAllarmiCaduta() { //funzione per recuperare gli allarmi caduta
        props.loading(true);
        let id_sensore = sensoriRes.filter((item) => item.tipo === "Sensore caduta")[0].id_sensore; //trova l'id del sensore caduta
        await allarmiNode.getAllarmiCaduta(accountData.token, id_sensore).then((res) => {
            setAllarmiCaduta(res.data);
            console.log(res.data)
        }).finally(() => {
            props.loading(false);
        });
    }

    async function allarmiCadutaSwitch(id, mode, stato) {    //funzione per aggiungere, modificare o eliminare un allarme caduta
        try {
            props.loading(true);
            switch (mode) {
                case 'add': //? ---------- AGGIUNGI ALLARME CADUTA
                    setAllarmiCaduta([...allarmiCaduta, { descrizione: '', stato: '0', inizio: '', fine: '' }]);
                    break;
                case 'edit':        //? ---------- MODIFICA ALLARME CADUTA

                    let indexEdit = allarmiCadutaMod.findIndex((x) => x.id === id);
                    if (indexEdit === -1) {
                        setAllarmiCadutaMod([...allarmiCadutaMod, { id: id, stato: stato }]);
                    } else {
                        allarmiCadutaMod[indexEdit].stato = stato;
                        setAllarmiCadutaMod([...allarmiCadutaMod]);
                    }
                    break;
                case 'del': //? ---------- ELIMINA ALLARME CADUTA
                    let indexAllarme = allarmiCaduta.findIndex((x) => x.id === id); //trova l'indice dell'allarme da eliminare

                    if (indexAllarme !== -1) {  //se l'allarme è presente in allarmiCaduta
                        setAllarmiCadutaDel([...allarmiCadutaDel, allarmiCaduta[indexAllarme].id]);
                        allarmiCaduta.splice(indexAllarme, 1);
                        setAllarmiCaduta([...allarmiCaduta]);
                    }
                    break;
                default:
                    break;
            }
            props.loading(false);
            return;
        } catch (e) {
            console.log(e);
            return;
        }
    }

    function enableGeof(x, id_thinkin) { //funzione per abilitare o disabilitare un geofence
        let ambiente = document.getElementById(x);
        if (ambiente === null) {
            return defDot;
        } else {
            if (ambiente.className === defDot) { //se il geofence è disabilitato
                ambiente.className = selDot;
                // aggiungo l'id_thinkin a alarms[alarmSel]
                alarms[alarmSel].valore.push(id_thinkin)
                setAlarms(alarms)
                console.log(alarms[alarmSel].valore)

            } else {
                ambiente.className = defDot; 
                for (let key in alarms[alarmSel].valore) {
                    if (alarms[alarmSel].valore[key] === id_thinkin) {
                        alarms[alarmSel].valore.splice(key, 1);
                        setAlarms(alarms)
                    }
                }
            }
        }
    }

    function Sens(azione, id) { //funzione per aggiungere, modificare o eliminare un sensore 
        console.log(id)
        if (azione === 'add') { //aggiungi sensore
            setSensoriSel([...sensoriSel, sensori.filter((item) => item.id === id)[0]]);
            setSensoriRes([...sensoriRes, sensori.filter((item) => item.id === id)[0]]);
            setTipoSensori([...tipoSensori, sensori.filter((item) => item.id === id)[0].tipo]);
        } else {
            let index;
            index = sensoriRes.findIndex((x) => x.id_sensore === id);
            setSensoriDel([...sensoriDel, index !== -1 ? sensoriRes.filter((item) => item.id_sensore === id)[0] : sensori.filter((item) => item.id === id)[0]]);

            let indexTipo = tipoSensori.indexOf(sensoriRes[index].tipo);
            tipoSensori.splice(indexTipo, 1);
            setTipoSensori([...tipoSensori]);

            sensoriRes.splice(index, 1);
            setSensoriRes([...sensoriRes]);
            for (let sensoresel in sensoriSel) {
                if (sensoresel.id_sensore === id) {
                    index = sensoriSel.findIndex((x) => x.id_sensore === id);
                    sensoriSel.splice(index, 1);
                    setSensoriSel([...sensoriSel]);
                }
            }
        }
    }

    useEffect(() => {
        getSensoriRes();
        getLibere();
        getSensori();
        //getAllarmiCaduta();

        //getStruttura();
        const item = scrollRef.current;
        item.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) item.scrollLeft += 20;
            else item.scrollLeft -= 20;
        });
        if (item.scrollWidth > item.clientWidth) {
            document.getElementsByClassName("popupNavigator")[0].style.display = "flex";
        } else {
            document.getElementsByClassName("popupNavigator")[0].style.display = "none";
        }
    }, []); 

    function openMiniPopup() { //funzione per aprire il popup per aggiungere un allarme
        let miniPop = document.getElementById("MiniPopup").style.display;
        if (miniPop === "none") {
            document.getElementById("MiniPopup").style.display = "flex";
        } else {
            document.getElementById("MiniPopup").style.display = "none";
        }
    }

    function delAlarm(index) { 
        // rimuovo da alarms l'allarme selezionato
        console.log(alarms[index])
        setSelGeoAllarmsDel([...selGeoAllarmsDel, alarms[index]])
        alarms.splice(index, 1)
        setAlarms(alarms)
        // aggiungo allarme da eliminare a selGeoAllarmsDel
    }

    function openProfiloPopup(operation) { //funzione per aprire il popup per aggiungere un profilo

        console.log("ciao m");

        getProfili();

        console.log("11111111111111111111111111");

        setOperation(operation);

        console.log("222222222222222222");

        console.log(status);

        let profPop = document.getElementById("ProfiloPopup").style.display;

        console.log("33333333333333333");

        console.log(status);

        if (profPop === "none") {
            document.getElementById("ProfiloPopup").style.display = "flex";
        } else {
            document.getElementById("ProfiloPopup").style.display = "none";
        }
    }

    async function getProfili() { //funzione per ottenere i profili
        props.loading(true);
        let tipo = status === 3 ? "Sensore geofencing" : status === 4 ? "Sensore letto" : status === 5 ? "Sensore caduta" : null;
        await profiliNode.getProfili(accountData.token, tipo).then((res) => {
            setProfili(res.data);
        }).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        getProfili();
    }, [status >= 3]);

    async function creaProfilo() { //funzione per creare un profilo

        console.log("funzione crea profilo");

        console.log(status);

        props.loading(true);
        let tipo = status === 3 ? "Sensore geofencing" : status === 4 ? "Sensore letto" : status === 5 ? "Sensore caduta" : status === 6 ? "Sensore presenza" : null;
        let allarmi = status === 3 ? alarms : status === 4 ? allarmiLetto : status === 5 ? allarmiCaduta : status === 6 ? allarmiassenzastanza : null;
        let id_allarmi = [];

        // allarmiassenzastanza

        console.log(tipo, allarmi, id_allarmi)

        console.log("xxxxzxzxzxzxzxzxzxzxzxzxzxzxxzxz");

        allarmi.map((item) => {
            // id_allarmi.push(item.id);

            console.log(id_allarmi, item.id);

        })

        if(status === 3 || status === 5 || status === 6){
            allarmi.map((item) => {
                id_allarmi.push(item.id);
                console.log('add!!!!!')
            })

            console.log('fungeeeee');
        }

        if(status === 4){ 
            allarmi.map((item) => {
                if(item.id_vuoto) id_allarmi.push(item.id_vuoto);
                if(item.id_occupato) id_allarmi.push(item.id_occupato);
            })
        }

        // if( status === 6){
        //     console.log("status === 6");
        //
        // }

        console.log(descrizione, tipo, id_allarmi);

        await profiliNode.creaProfilo(accountData.token, descrizione, tipo, id_allarmi).finally(() => { //creo il profilo
            getProfili();
            openProfiloPopup();
            props.loading(false);
        });
    }

    async function loadProfilo() {  //funzione per caricare un profilo
        props.loading(true);
        let tipo = status === 3 ? "Sensore geofencing" : status === 4 ? "Sensore letto" : status === 5 ? "Sensore caduta" : null; 
        await allarmiNode.caricaProfilo(accountData.token, idProfilo, sensoriRes.filter((item) => item.tipo === tipo)[0].id_sensore).then((res) => { 
            console.log(res)
            if (status === 3) { getAllarmiGeo(); console.log("3") }
            if (status === 4) { getAllarmiLetto(); console.log("4") }
            if (status === 5) { getAllarmiCaduta(); console.log("5") }
            if (status === 6) { getallarmiassenzastanza(); console.log("6") }
        }
        ).finally(() => {
            openProfiloPopup();
            props.loading(false);
        });
    }

    // console.log(accountData.tipo !== 4);

    const profilopopup = ( //popup per creare o caricare un profilo
        <div id="ProfiloPopup" className="popupContent" style={{ display: "none", position: "absolute", top: "45%", left: "45%", transform: "translate(-50%, -50%)", }}>

            <div id="PopupBody" style={{ width: "30em" }}>
                <div style={{ padding: "1.5em", display: operation !== "salva" ? "none" : "" }}>
                    <label>Nome profilo da salvare</label>
                    <input
                        type="text"
                        placeholder={langSet.inserisciNome}
                        onChange={(e) => setDescrizione(e.target.value)}
                        style={{ width: "90%", margin: "1em" }}
                    />
                </div>
                <div style={{ padding: "1.5em", display: operation !== "carica" ? "none" : "" }}>
                    <label>Scegli profilo da caricare</label>
                    <select defaultValue="0" style={{ width: "99%", margin: "1em", padding: "0px" }} onChange={(e) => setIdProfilo(e.target.value)}>
                        <option value="0" disabled>Seleziona un profilo</option>
                        {profili.map((profilo, i) => {
                            return (<option key={i} value={profilo.id}>{profilo.descrizione}</option>);
                        })}
                    </select>
                </div>
                <div id="PopupFooter">
                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => openProfiloPopup()}>
                        <p>{langSet.annulla}</p>
                        <img className="icon-popup" src={close} alt="close" />
                    </div>
                    <div className="selettore selettoreFlex selettoreBlue" onClick={() => { operation === "salva" ? creaProfilo() : loadProfilo() }}>
                        <p>{langSet.salva}</p>
                        <img className="icon-popup" src={confirmW} alt="confirm" />
                    </div>
                </div>
            </div>
        </div>
    );

    const minipopup = ( //popup per creare un allarme
        <div id="MiniPopup" style={{ left: alarms.length * 10 + 7 + "em", display: "none" }}>
            <table className="minipopTable">
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <label>{langSet.nomeProgrammazione}</label>
                            <input
                                type="text"
                                placeholder={langSet.inserisciNome}
                                onChange={(e) => setDescrizione(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>{langSet.dalle}</label>
                            <input
                                type="time"
                                defaultValue="00:00"
                                onChange={(e) => setInizio(e.target.value)}
                                style={{ width: "75%" }}
                            />
                        </td>
                        <td>
                            <label>{langSet.alle}</label>
                            <input
                                type="time"
                                defaultValue="00:00"
                                onChange={(e) => setFine(e.target.value)}
                                style={{ width: "75%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <br />
                            <div
                                className="selettore selettoreFlex selettoreBorder"
                                onClick={() => openMiniPopup()}
                            >
                                <p>{langSet.annulla}</p>
                                <img className="icon-popup" src={close} alt='close' />
                            </div>
                            <div
                                className="selettore selettoreFlex selettoreBlue"
                                onClick={() => Alarm(descrizione, inizio, fine, "Sensore geofencing", [], [getPianoRes()], false)}
                            >
                                <p>{langSet.salva}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
    const minipopupedit = ( //popup per modificare un allarme
        <div id="MiniPopup" style={{ left: alarmSel * 10.5 + 9.25 + "em", display: "none" }}>
            <div className="delMiniPopup">
                <img src={del} alt="delete" onClick={() => { delAlarm(alarmSel); openMiniPopup() }} /> 
            </div> 
            <table className="minipopTable">
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <label>{langSet.nomeProgrammazione}</label>
                            <input
                                type="text"
                                placeholder={langSet.inserisciNome}
                                value={descrizione}
                                onChange={(e) => setDescrizione(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>{langSet.dalle}</label>
                            <input
                                type="time"
                                value={inizio}
                                onChange={(e) => setInizio(e.target.value)}
                                style={{ width: "75%" }}
                            />
                        </td>
                        <td>
                            <label>{langSet.alle}</label>
                            <input
                                type="time"
                                value={fine}
                                onChange={(e) => setFine(e.target.value)}
                                style={{ width: "75%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <br />
                            <div
                                className="selettore selettoreFlex selettoreBorder"
                                onClick={() => openMiniPopup()}
                            >
                                <p>{langSet.annulla}</p>
                                <img src={close} alt='close' />
                            </div>
                            <div
                                className="selettore selettoreFlex selettoreBlue"
                                onClick={() => { alarms[alarmSel].descrizione = descrizione; alarms[alarmSel].inizio = inizio; alarms[alarmSel].fine = fine; openMiniPopup(); setAlarms(alarms); getInfoGeoAllarm(alarmSel) }}  //modifica l'allarme
                            >
                                <p>{langSet.salvaModifica}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );


    return ( //pagina di gestione dei profili
        <>
            <div id="Header" className="headerPopup" style={{justifyContent: "start", overflowY: "auto"}}>

                <div className={status === 0 ? selSelettore : defSelettore} onClick={() => setStatus(0)}>
                    <p>{langSet.datiPersonali}</p>
                </div>

                <div style={{ display: accountData.tipo === 3 || accountData.tipo === 4 ? "none" : "" }} className={status === 1 ? selSelettore : defSelettore} onClick={() => setStatus(1)}>
                    <p>{langSet.stanza}</p>
                </div>
                {/*<div*/}
                {/*    className={`${status === 1 ? selSelettore : defSelettore} ${accountData.tipo !== 4 ? 'disabled' : ''}`}*/}
                {/*    onClick={() => {*/}
                {/*        if (accountData.tipo === 4) setStatus(1);*/}
                {/*    }}>*/}
                {/*    <p>{langSet.stanza}</p>*/}
                {/*</div>*/}


                <div style={{ display: accountData.tipo === 3 || accountData.tipo === 4 ? "none" : "" }} className={status === 2 ? selSelettore : defSelettore}
                     onClick={() => setStatus(2)}>
                    <p>{langSet.sensoriPersonali}</p>
                </div>


                {/*<div*/}
                {/*    className={`${status === 2 ? selSelettore : defSelettore} ${accountData.tipo !== 4 ? 'disabled' : ''}`}*/}
                {/*    onClick={() => {*/}
                {/*        if (accountData.tipo === 4) setStatus(2);*/}
                {/*    }}>*/}
                {/*    <p>{langSet.sensoriPersonali}</p>*/}
                {/*</div>*/}

                <div style={{ display: accountData.tipo === 3 || accountData.tipo === 4 ? "none" : "" }}
                    className={status === 3 ? selSelettore : tipoSensori.includes("Sensore geofencing") ? defSelettore : "disabled " + defSelettore}
                    onClick={tipoSensori.includes("Sensore geofencing") ? async () => {
                        await getAllarmiGeo();
                        setStatus(3);
                    } : null}>
                    <p>Allerta Geofencing</p>
                </div>
                <div
                    className={status === 4 ? selSelettore : tipoSensori.includes("Sensore letto") ? defSelettore : "disabled " + defSelettore}
                    onClick={tipoSensori.includes("Sensore letto") ? async () => {
                        await getAllarmiLetto();
                        setStatus(4);
                    } : null}>
                    <p>Allerta Letto</p>
                </div>
                <div
                    className={status === 5 ? selSelettore : tipoSensori.includes("Sensore caduta") ? defSelettore : "disabled " + defSelettore}
                    onClick={tipoSensori.includes("Sensore caduta") ? async () => {
                        await getAllarmiCaduta();
                        setStatus(5)
                    } : null}>
                    <p>Allerta Caduta</p>
                </div>
                <div
                    className={status === 6 ? selSelettore : tipoSensori.includes("Sensore presenza stanza") ? defSelettore : "disabled " + defSelettore}
                    onClick={tipoSensori.includes("Sensore presenza stanza") ? async () => {
                        await getallarmiassenzastanza();
                        setStatus(6)
                    } : null}>
                    <p>Allerta Presenza</p>
                </div>
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{display: status === 0 ? "" : "none"}}>
                    <tbody>
                    <tr>
                        <td width="15%" rowSpan={2} className="schedaImg">
                            <img src={userPurple} alt="position"/>
                        </td>
                        <td width="17.5%">
                            <label>{langSet.nome}</label>
                            {mod === 0 || accountData.tipo !== 4 ? <p>{nome ?? ''}</p> :
                                <input
                                    type="text"
                                    value={nome}
                                    placeholder={langSet.inserisciNome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            }
                        </td>
                        <td width="17.5%">
                            <div className="divSchema">
                                <label>{langSet.cognome}</label>
                                {mod === 0 || accountData.tipo !== 4 ? <p>{cognome ?? ''}</p> :
                                    <input
                                        type="text"
                                        value={cognome}
                                        placeholder={langSet.inserisciCognome}
                                        onChange={(e) => setCognome(e.target.value)}
                                    />
                                }
                            </div>
                        </td>
                        <td style={{display: accountData.tipo === 3 || accountData.tipo === 4 ? "none" : ""}}
                            width="50%" valign="bottom" rowSpan={2}>
                            {mod === 0 ?
                                <div
                                    className="selettore selettoreFlex selettoreViolet width-5rem"
                                    onClick={() => setMod(1)}
                                    style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                                >
                                    <p>{langSet.modifica}</p>
                                    <img src={modifica} alt='modifica' style={{marginLeft: "5px"}}
                                         className="icon-popup"/>
                                </div>
                                : <>
                                    <div
                                        className={defContinua}
                                        onClick={() => salvaModifiche("residente")}
                                        style={{width: "5rem"}}
                                    >
                                        <p>{langSet.salva}</p>
                                        <img src={confirmW} alt='confirmW'
                                             style={{width: "0.9rem", marginLeft: "5px"}}/>
                                    </div>
                                    <div className="selettore selettoreFlex selettoreViolet width-5rem" onClick={() => {
                                        setMod(0);
                                        setCognome(props.residente.cognome);
                                        setNome(props.residente.nome)
                                    }}>
                                        <p>{langSet.annulla}</p>
                                        <img src={close} alt="close" style={{width: "0.9rem", marginLeft: "5px"}}/>
                                    </div>
                                </>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>{langSet.identificativo}</label>
                            <p>{id}</p>
                        </td>
                        <td>
                            <label>{langSet.dataDiRegistrazione}</label>
                            <p style={{width: "10em", position: "relative", top: ".25em"}}>
                                {registrazione}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="scheda" style={{display: status === 1 ? "" : "none"}}>
                    <tbody>
                    <tr>
                        <td width="15%" rowSpan={2} className="schedaImg">
                            <img src={residentiPurple} className="icon-popup" alt="position"/>
                        </td>
                        <td width="17.5%">
                            <label>{langSet.camera}</label>
                            {mod === 0 || accountData.tipo !== 4 ? <p>{camera}</p> :
                                <select onChange={(e) => setCamera(e.target.value) && setStanzRes()} defaultValue={0}>
                                    <option value={0} disabled>{camera}</option>
                                    {stanze === undefined ?
                                        <option disabled>{langSet.erroreCaricamento}</option>
                                        : stanze.map((stanza, index) => {
                                            return (
                                                <option key={index} value={stanza}>
                                                    {stanza}
                                                </option>
                                            );
                                        })}
                                </select>
                            }
                        </td>

                        <td valign="bottom">
                            {mod === 0 ?
                                <div
                                    className="selettore selettoreFlex selettoreViolet"
                                    onClick={() => setMod(1)}
                                    style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                                >
                                    <p>{langSet.modifica}</p>
                                    <img src={modifica} alt='modifica' className="icon-popup"/>
                                </div>
                                : <>
                                    <div
                                        className={defContinua}
                                        onClick={() => salvaModifiche("stanza")}
                                    >
                                        <p>{langSet.salva}</p>
                                        <img src={confirmW} className="icon-popup" alt='confirmW'/>
                                    </div>
                                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => {
                                        setMod(0);
                                        setCamera(props.residente.stanza)
                                    }}>
                                        <p>{langSet.annulla}</p>
                                        <img src={close} className="icon-popup" alt="close"/>
                                    </div>
                                </>
                            }
                        </td>
                    </tr>
                    </tbody>

                </table>
                <div style={{display: status === 2 ? "" : "none"}}>


                    {/*    <table id="TitoliLista" >*/}
                    {/*        <tbody>*/}
                    {/*            <tr>*/}
                    {/*                <td width="15%" style={{ border: 0 }}>{langSet.nome}  cc</td>*/}
                    {/*                <td width="15%" style={{ border: 0 }}>ID </td>*/}
                    {/*                <td width="15%" style={{ border: 0 }}>{langSet.tipo} </td>*/}
                    {/*                <td width="15%" style={{ border: 0 }}>{langSet.stato} </td>*/}
                    {/*                <td width="15%" style={{ border: 0 }}>{langSet.batteria} </td>*/}
                    {/*                <td width="25%" style={{ border: 0 }}></td>*/}
                    {/*            </tr>*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*    <Select id="Select" isDisabled={mod === 0 || accountData.tipo !== 0 ? true : false}*/}
                    {/*        styles={{*/}
                    {/*            control: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", overflowX: "hidden", boxShadow: "none", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),*/}
                    {/*            input: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px" }),*/}
                    {/*            menu: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", boxShadow: "none",  border: "solid 1px #2B318A", overflowX: "hidden", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),*/}
                    {/*            option: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px", overflowY: "scroll", maxHeight: "25em", overflowX: "hidden"}),*/}
                    {/*          }}*/}
                    {/*        options={filteredOptions}*/}
                    {/*        value={selectedOption}*/}
                    {/*        onChange={(selected) => {setSelectedOption(selected); Sens("add", selected.value)}}*/}
                    {/*        placeholder={langSet.selezionaSensore}*/}
                    {/*        isSearchable*/}
                    {/*        onInputChange={(inputValue) => setSearchTerm(inputValue)}*/}
                    {/*        /><i style={{ fontSize: "90%", color: "#d3d3d3" }}>* {langSet.massimo1PerTipologia}</i><i style={{ fontSize: "90%", color: "#d3d3d3", display: mod === 1 ? "" : "none" }}>* {langSet.massimo1PerTipologia}</i>*/}
                    {/*    <div style={{ overflow: "scroll", maxHeight: "25em" }}>*/}
                    {/*        {sensoriRes.length > 0 ?*/}
                    {/*            sensoriRes.map((sensore, i) => (*/}

                    {/*                <table id="Lista" key={i}>*/}
                    {/*                    <tbody>*/}
                    {/*                        <tr key={sensore}>*/}
                    {/*                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.nome}</td>*/}
                    {/*                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.id_sensore ?? sensore.id}</td>*/}
                    {/*                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.tipo}</td>*/}
                    {/*                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.stato}</td>*/}
                    {/*                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.batteria}</td>*/}
                    {/*                            <td width="25%" style={{ padding: 0, paddingLeft: ".5em" }}>*/}
                    {/*                                {mod === 0 ? <div></div> :*/}
                    {/*                                    <div className="selettore selettoreFlex selettoreViolet"*/}
                    {/*                                         onClick={() => Sens("del", sensore.id_sensore)}>*/}
                    {/*                                        <p>{langSet.disassocia}</p>*/}
                    {/*                                        <img src={delet} className="icon-popup" alt="delete"/>*/}
                    {/*                                    </div>*/}
                    {/*                                }*/}
                    {/*                            </td>*/}
                    {/*                        </tr>*/}
                    {/*                    </tbody>*/}
                    {/*                </table>*/}
                    {/*            )) : <p style={{ textAlign: "center", margin: "1em", display: "" }}>{langSet.nessunSensore}</p>*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*    <div className="navigators">*/}
                    {/*        {mod === 0 ?*/}
                    {/*            <div*/}
                    {/*                className="selettore selettoreFlex selettoreViolet"*/}
                    {/*                onClick={() => setMod(1)}*/}
                    {/*                style={{ display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null }}*/}
                    {/*            >*/}
                    {/*                <p>{langSet.modifica}</p>*/}
                    {/*                <img src={modifica} alt='modifica' className="icon-popup" />*/}
                    {/*            </div>*/}
                    {/*            : <>*/}
                    {/*                <div*/}
                    {/*                    className={defContinua}*/}
                    {/*                    onClick={() => salvaModifiche("sensori")}*/}
                    {/*                >*/}
                    {/*                    <p>{langSet.salva}</p>*/}
                    {/*                    <img src={confirmW} className="icon-popup" alt='confirmW' />*/}
                    {/*                </div>*/}
                    {/*                <div className="selettore selettoreFlex selettoreViolet" onClick={() => { setMod(0); getSensoriRes() }}>*/}
                    {/*                    <p>{langSet.annulla}</p>*/}
                    {/*                    <img src={close} className="icon-popup" alt="close" />*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <table id="TitoliLista">
                        <tbody>
                        <tr>
                            <td width="15%" style={{border: 0}}>{langSet.nome} cc</td>
                            <td width="15%" style={{border: 0}}>ID</td>
                            <td width="15%" style={{border: 0}}>{langSet.tipo} </td>
                            <td width="15%" style={{border: 0}}>{langSet.stato} </td>
                            <td width="15%" style={{border: 0}}>{langSet.batteria} </td>
                            <td width="25%" style={{border: 0}}></td>
                        </tr>
                        </tbody>
                    </table>
                    <Select id="Select" isDisabled={mod === 0 || accountData.tipo !== 0 ? true : false}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '300px',
                                    fontSize: "90%",
                                    color: "#2B318A",
                                    fontWeight: "600",
                                    borderRadius: "20px",
                                    overflowX: "hidden",
                                    boxShadow: "none",
                                    "&:hover": {borderRadius: "25px", boxShadow: "none", width: "300px"}
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: "#2B318A",
                                    fontWeight: "600",
                                    fontSize: "90%",
                                    textAlign: "center",
                                    marginLeft: "10px"
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    width: '300px',
                                    fontSize: "90%",
                                    color: "#2B318A",
                                    fontWeight: "600",
                                    borderRadius: "20px",
                                    boxShadow: "none",
                                    border: "solid 1px #2B318A",
                                    overflowX: "hidden",
                                    "&:hover": {borderRadius: "25px", boxShadow: "none", width: "300px"}
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    color: "#2B318A",
                                    fontWeight: "600",
                                    fontSize: "90%",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    overflowY: "scroll",
                                    maxHeight: "25em",
                                    overflowX: "hidden"
                                }),
                            }}
                            options={filteredOptions}
                            value={selectedOption}
                            onChange={(selected) => {
                                setSelectedOption(selected);
                                Sens("add", selected.value)
                            }}
                            placeholder={langSet.selezionaSensore}
                            isSearchable
                            onInputChange={(inputValue) => setSearchTerm(inputValue)}
                    /><i style={{fontSize: "90%", color: "#d3d3d3"}}>* {langSet.massimo1PerTipologia}</i><i style={{
                    fontSize: "90%",
                    color: "#d3d3d3",
                    display: mod === 1 ? "" : "none"
                }}>* {langSet.massimo1PerTipologia}</i>
                    <div style={{overflow: "scroll", maxHeight: "25em"}}>
                        {sensoriRes.length > 0 ?
                            sensoriRes.map((sensore, i) => (

                                <table id="Lista" key={i}>
                                    <tbody>
                                    <tr key={sensore}>
                                        <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>{sensore.nome}</td>
                                        <td width="15%" style={{
                                            padding: 0,
                                            paddingLeft: ".5em"
                                        }}>{sensore.id_sensore ?? sensore.id}</td>
                                        <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>{sensore.tipo}</td>
                                        <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>{sensore.stato}</td>
                                        <td width="15%"
                                            style={{padding: 0, paddingLeft: ".5em"}}>{sensore.batteria}</td>
                                        <td width="25%" style={{padding: 0, paddingLeft: ".5em"}}>
                                            {mod === 0 ? <div></div> :
                                                <div className="selettore selettoreFlex selettoreViolet"
                                                     onClick={() => Sens("del", sensore.id_sensore)}>
                                                    <p>{langSet.disassocia}</p>
                                                    <img src={delet} className="icon-popup" alt="delete"/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )) :
                            <p style={{textAlign: "center", margin: "1em", display: ""}}>{langSet.nessunSensore}</p>
                        }

                    </div>
                    <div className="navigators">
                        {mod === 0 ?
                            <div
                                className="selettore selettoreFlex selettoreViolet"
                                onClick={() => setMod(1)}
                                style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup"/>
                            </div>
                            : <>
                                <div
                                    className={defContinua}
                                    onClick={() => salvaModifiche("sensori")}
                                >
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} className="icon-popup" alt='confirmW'/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => {
                                    setMod(0);
                                    getSensoriRes()
                                }}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} className="icon-popup" alt="close"/>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div style={{display: status === 3 ? "" : "none"}}>

                    <table id="TitoliLista">
                        <tbody>
                        <tr style={{height: "4.5em"}}>
                            <td width="10%" style={{
                                fontSize: "85%",
                                color: "#000"
                            }}>{langSet.nomeProgrammazione}<br/><br/>{langSet.orario}</td>
                            {
                                alarms.map((alarm, i) => (
                                    <td width="12.75%" key={i}>
                                        <div
                                            className="selettore selettoreFlex selettoreViolet left"
                                            style={{
                                                padding: ".75em",
                                                width: "10em",
                                                cursor: "pointer",
                                                flexDirection: "column",
                                                boxShadow: alarmSel === i ? "0px 5px 5px rgba(90, 129, 250, 0.3)" : "none"
                                            }}
                                            key={i}
                                            onClick={mod === 1 && alarmSel === i ? () => {
                                                setEditPopup(1);
                                                openMiniPopup();
                                                setAlarmSel(i);
                                            } : () => {
                                                getInfoGeoAllarm(i);
                                                getStruttura();
                                                setAlarmSel(i)
                                            }}
                                        >
                                            <p> {alarm.descrizione}</p>
                                            <p> {alarm.inizio} - {alarm.fine}</p>
                                        </div>
                                    </td>
                                ))
                            }
                            <td width="12.75%" style={{display: mod === 1 ? "" : "none"}}>
                                <div
                                    className="selettore selettoreFlex selettoreViolet left"
                                    style={{lineHeight: "1.5em", padding: ".75em", width: "10em", cursor: "pointer"}}
                                    onClick={() => {
                                        setEditPopup(0);
                                        openMiniPopup()
                                    }}>
                                    <p>{langSet.aggiungi}<br/>{langSet.programmazione}</p>
                                    <img src={add} alt="add"/>
                                </div>
                            </td>
                            <td style={{opacity: 0}}>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {editPopup === 1 ? minipopupedit : minipopup}<br/>
                    <div className="popupCardContainer" ref={scrollRef}>
                        {struttura.map((piano, i) => (
                            <div className="popupCard" style={alarms[alarmSel] === undefined ? {display: "none"} : {
                                paddingLeft: "1em",
                                minWidth: "15em",
                                background: "#f7f9ff",
                                color: "#2B318A"
                            }} key={i}>
                                <table
                                    className={mod === 0 ? " scheda schedaNoBorder disabled" : "scheda schedaNoBorder tdPointer"}>
                                    <tbody>
                                    <tr>

                                        <td onClick={mod === 1 ? () => {
                                            enableGeof(piano.id)
                                        } : null}>
                                            <div className={defDot} id={piano.id}/>
                                            {piano.descrizione}
                                        </td>
                                    </tr>
                                    {piano[piano.id].map((ala, j) => (
                                        <>
                                            <tr>
                                                <td onClick={mod === 1 ? () => {
                                                    enableGeof(ala.id)
                                                } : null}>
                                                    <div style={{
                                                        color: "rgba(200, 200, 200, 0.6)",
                                                        marginLeft: "1.5em",
                                                        float: "left",
                                                        position: "relative",
                                                        bottom: ".45em"
                                                    }}>↳
                                                    </div>
                                                    <div className={defDot} id={ala.id}/>
                                                    {ala.descrizione}
                                                </td>
                                            </tr>
                                            {ala[ala.id].map((corridoio, k) => (
                                                <>
                                                    <tr>
                                                        <td key={k} onClick={mod === 1 ? () => {
                                                            enableGeof(corridoio.id, corridoio.id_thinkin);
                                                            setenablemod(1)
                                                        } : null}>
                                                            <div style={{
                                                                color: "rgba(200, 200, 200, 0.6)",
                                                                marginLeft: "3em",
                                                                float: "left",
                                                                position: "relative",
                                                                bottom: ".45em"
                                                            }}>↳
                                                            </div>
                                                            <div className={getzone(corridoio.id_thinkin, corridoio.id)}
                                                                 id={corridoio.id}/>
                                                            {corridoio.descrizione}
                                                        </td>
                                                    </tr>
                                                    {
                                                        corridoio[corridoio.id].map((stanza, l) => (
                                                            <tr>
                                                                <td key={l}
                                                                    onClick={mod === 1 ? () => enableGeof(stanza.id, stanza.id_thinkin) : null}>
                                                                    <div style={{
                                                                        color: "rgba(200, 200, 200, 0.6)",
                                                                        marginLeft: "4.5em",
                                                                        float: "left",
                                                                        position: "relative",
                                                                        bottom: ".45em"
                                                                    }}>↳
                                                                    </div>
                                                                    <div
                                                                        className={getzone(stanza.id_thinkin, stanza.id)}
                                                                        id={stanza.id}/>
                                                                    {stanza.descrizione}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            ))}
                                        </>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                        ))}
                    </div>
                    <div className="popupNavigator">
                        <img src={arrowPurple} alt="arrowPurple" style={{transform: "rotate(90deg)"}}
                             onClick={() => scroll("left")}/>
                        <p>&emsp;-&emsp;</p>
                        <img src={arrowPurple} alt="arrowPurple" style={{transform: "rotate(-90deg)"}}
                             onClick={() => scroll("right")}/>
                    </div>
                    {status === 3 ? profilopopup : null}
                    <div className="popupButtons">
                        {mod === 0 ?
                            <>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}
                                     style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                                >
                                    <p>{langSet.modifica}</p>
                                    <img src={modifica} className="icon-popup" alt='modifica'/>
                                </div>
                            </> :
                            <>
                                <div
                                    className={defContinua}
                                    onClick={() => {
                                        salvaModifiche("allarmiGeo")
                                    }}
                                >
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} className="icon-popup" alt='confirmW'/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => {
                                    setMod(0);
                                    setCognome(props.residente.cognome);
                                    setNome(props.residente.nome)
                                }}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} className="icon-popup" alt="close"/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("salva")}>
                                    <p>Salva come Profilo</p>
                                    <img src={save} className="icon-popup" alt="save" style={{width: "1.15em"}}/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("carica")}>
                                    <p>Carica Profilo</p>
                                    <img src={carica} alt="carica" className="icon-popup" style={{width: "1.15em"}}/>
                                </div>
                            </>
                        }
                    </div>
                    <div className="popupLegend">
                        <div className={defDot}/>
                        <div style={{float: "left"}}>{langSet.noAllarme}&ensp;</div>
                        <div className={selDot}/>
                        <div style={{float: "left"}}>{langSet.allarme}&ensp;</div>
                    </div>


                </div>
                <div style={{display: status === 4 ? "" : "none"}}>
                    <table id="TitoliLista">
                        <tbody>
                        <tr>
                            <td width="15%" style={{border: 0}}>{langSet.descrizione}</td>
                            <td width="20%" style={{border: 0}}>{langSet.daA} </td>
                            <td width="12.5%" style={{border: 0}}>{langSet.ilLettoEOccupato} </td>
                            <td width="12.5%" style={{border: 0}}>{langSet.ilLettoEVuoto} </td>
                            <td width="50.0%" style={{border: 0}}></td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={{overflow: "scroll", minHeight: "20em", maxHeight: "25em"}}>
                        {allarmiLetto.length > 0 ?
                            allarmiLetto.map((allarme, i) => (
                                <table id="Lista" key={i}>
                                    {mod === 0 ?
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%"
                                                style={{padding: 0, paddingLeft: ".5em"}}>{allarme.descrizione}</td>
                                            <td width="20%" style={{
                                                padding: 0,
                                                paddingLeft: ".5em"
                                            }}>{`${allarme.inizio} - ${allarme.fine}`}</td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {/*{allarme.letto_occupato}*/}
                                                <Switch
                                                    checked={allarme.letto_occupato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                    disabled
                                                />
                                            </td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {/*{allarme.letto_vuoto}*/}

                                                <Switch
                                                    checked={allarme.letto_vuoto === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                    disabled
                                                />

                                            </td>
                                            <td width="50.0%" style={{padding: 0, paddingLeft: ".5em"}}>
                                            </td>
                                        </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id_vuoto || allarme.id_occupato ? allarme.descrizione :
                                                    <input type="text" placeholder="Inserisci descrizione"
                                                           onChange={(e) => {
                                                               allarmiLetto[i].descrizione = e.target.value;
                                                               setAlarmiLetto([...allarmiLetto])
                                                           }} style={{width: "85%"}}/>}
                                            </td>
                                            <td width="20%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id_vuoto || allarme.id_occupato ? <>{allarme.inizio} - {allarme.fine}</> : <>
                                                    <input type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                        allarmiLetto[i].inizio = e.target.value;
                                                        setAlarmiLetto([...allarmiLetto])
                                                    }}/>&ensp;
                                                    <input type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                        allarmiLetto[i].fine = e.target.value;
                                                        setAlarmiLetto([...allarmiLetto])
                                                    }}/></>}
                                            </td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>

                                                <select value={allarme.letto_occupato}
                                                        style={{width: "95%", padding: "0", display: "none"}}
                                                        onChange={(e) => {
                                                            if (allarme.id_occupato) {
                                                                alarmsList('', '', 'edit', allarme.id_occupato, e.target.value);
                                                            }
                                                            allarmiLetto[i].letto_occupato = e.target.value;
                                                            setAlarmiLetto([...allarmiLetto])
                                                        }} required>
                                                    <option value="0" disabled>- - -</option>
                                                    {selettoreAllarmiLetto.map((currSel, index) => <option key={index}
                                                                                                           value={currSel}>{currSel}</option>)}
                                                </select>

                                                <Switch
                                                    onChange={(checked) => {
                                                        const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                        if (allarme.id_occupato) {
                                                            alarmsList('', '', 'edit', allarme.id_occupato, newValue);
                                                        }
                                                        allarmiLetto[i].letto_occupato = newValue;
                                                        setAlarmiLetto([...allarmiLetto]);
                                                    }}
                                                    checked={allarme.letto_occupato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                />

                                            </td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>

                                                <select value={allarme.letto_vuoto}
                                                        style={{width: "95%", padding: "0", display: "none"}}
                                                        onChange={(e) => {
                                                            if (allarme.id_vuoto) {
                                                                alarmsList('', '', 'edit', allarme.id_vuoto, e.target.value);
                                                            }
                                                            allarmiLetto[i].letto_vuoto = e.target.value;
                                                            setAlarmiLetto([...allarmiLetto])
                                                        }} required>
                                                    <option value="0" disabled>- - -</option>
                                                    {selettoreAllarmiLetto.map((currSel, index) => <option key={index}
                                                                                                           value={currSel}>{currSel}</option>)}
                                                </select>

                                                <Switch
                                                    onChange={(checked) => {
                                                        const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                        if (allarme.id_vuoto) {
                                                            alarmsList('', '', 'edit', allarme.id_vuoto, newValue);
                                                        }
                                                        allarmiLetto[i].letto_vuoto = newValue;
                                                        setAlarmiLetto([...allarmiLetto]);
                                                    }}
                                                    checked={allarme.letto_vuoto === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                />

                                            </td>
                                            <td width="50.0%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                <div className="selettore selettoreFlex selettoreBorder"
                                                     onClick={() => {
                                                         if (allarme.id_vuoto || allarme.id_occupato) {
                                                             alarmsList(allarme.id_vuoto, allarme.id_occupato, 'del')
                                                         } else {
                                                             allarmiLetto.splice(i);
                                                             setAlarmiLetto([...allarmiLetto])
                                                         }
                                                     }}>
                                                    <p>{langSet.elimina}</p>
                                                    <img src={delet} alt="delete"/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    }
                                </table>
                            )) :
                            <p style={{textAlign: "center", margin: "1em"}}>Nessuna Allerta Impostata</p>
                        }
                    </div>
                    {status === 4 ? profilopopup : null}
                    <div className="navigators">
                        {mod === 0 ?
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}
                                 style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup"/>
                            </div>
                            : <>
                                <div className="selettore selettoreFlex selettorePurple normPosition"
                                     onClick={() => alarmsList('', '', 'add')}>
                                    <p>{langSet.aggiungi}</p>
                                    <img className="icon-popup" src={addW} alt="add"/>
                                </div>
                                <div className={defContinua} onClick={() => salvaModifiche("allarmiLetto")}>
                                    <p>{langSet.salva}</p>
                                    <img className="icon-popup" src={confirmW} alt='arrow'/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => {
                                    setMod(0)
                                }}>
                                    <p>{langSet.annulla}</p>
                                    <img className="icon-popup" src={close} alt="close"/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("salva")}>
                                    <p>Salva come Profilo</p>
                                    <img className="icon-popup" src={save} alt="save" style={{width: "1.15em"}}/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("carica")}>
                                    <p>Carica Profilo</p>
                                    <img className="icon-popup" src={carica} alt="carica" style={{width: "1.15em"}}/>
                                </div>
                            </>
                        }
                    </div>

                </div>
                <div style={{display: status === 5 ? "" : "none"}}>
                    <table id="TitoliLista">
                        <tbody>
                        <tr>
                            <td width="15%" style={{border: 0}}>{langSet.descrizione}</td>
                            <td width="20%" style={{border: 0}}>{langSet.daA}</td>
                            <td width="12.5%" style={{border: 0}}>Allerta Caduta</td>
                            <td width="62.5%" style={{border: 0}}></td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={{overflow: "scroll", minHeight: "20em", maxHeight: "25em"}}>
                        {allarmiCaduta.length > 0 ?
                            allarmiCaduta.map((allarme, i) => (
                                <table id="Lista" key={i}>
                                    {mod === 0 ?
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%"
                                                style={{padding: 0, paddingLeft: ".5em"}}>{allarme.descrizione}</td>
                                            <td width="20%" style={{
                                                padding: 0,
                                                paddingLeft: ".5em"
                                            }}>{`${allarme.inizio} - ${allarme.fine}`}</td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {/*{allarme.stato}*/}
                                                <Switch
                                                    checked={allarme.stato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                    disabled
                                                />
                                            </td>
                                            <td width="62.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                            </td>
                                        </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id ? allarme.descrizione :
                                                    <input type="text" placeholder={langSet.inserisciDescrizione}
                                                           onChange={(e) => {
                                                               allarmiCaduta[i].descrizione = e.target.value;
                                                               setAllarmiCaduta([...allarmiCaduta])
                                                           }} style={{width: "85%"}}/>}
                                            </td>
                                            <td width="20%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id ? <>{allarme.inizio} - {allarme.fine}</> : <><input
                                                    type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                    allarmiCaduta[i].inizio = e.target.value;
                                                    setAllarmiCaduta([...allarmiCaduta])
                                                }}/>&ensp;
                                                    <input type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                        allarmiCaduta[i].fine = e.target.value;
                                                        setAllarmiCaduta([...allarmiCaduta])
                                                    }}/></>}
                                            </td>

                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                <select className="p-0" value={allarme.stato}
                                                        style={{width: "95%", padding: "0", display: 'none'}}
                                                        onChange={(e) => {
                                                            if (allarme.id) {
                                                                allarmiCadutaSwitch(allarme.id, 'edit', e.target.value);
                                                            }
                                                            allarmiCaduta[i].stato = e.target.value;
                                                            setAllarmiCaduta([...allarmiCaduta])
                                                        }} required>
                                                    <option value="0" disabled>- - -</option>
                                                    {selettoreAllarmiLetto.map((currSel, index) => <option key={index}
                                                                                                           value={currSel}>{currSel}</option>)}
                                                </select>

                                                <Switch
                                                    onChange={(checked) => {
                                                        const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                        if (allarme.id) {
                                                            allarmiCadutaSwitch(allarme.id, 'edit', newValue);
                                                        }
                                                        allarmiCaduta[i].stato = newValue;
                                                        setAllarmiCaduta([...allarmiCaduta]);
                                                    }}
                                                    checked={allarme.stato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                />

                                            </td>
                                            <td width="62.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                <div className="selettore selettoreFlex selettoreBorder"
                                                     onClick={() => allarmiCadutaSwitch(allarme.id, 'del')}>
                                                    <p>{langSet.elimina}</p>
                                                    <img src={delet} alt="delete"/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    }
                                </table>
                            )) :
                            <p style={{textAlign: "center", margin: "1em"}}>Nessuna Allerta Impostata</p>
                        }
                    </div>
                    {status === 5 ? profilopopup : null}
                    <div className="navigators">
                        {mod === 0 ?
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}
                                 style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : null}}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup"/>
                            </div>
                            : <>
                                <div className="selettore selettoreFlex selettorePurple normPosition"
                                     onClick={() => allarmiCadutaSwitch('', 'add')}>
                                    <p>{langSet.aggiungi}</p>
                                    <img src={addW} className="icon-popup" alt="add"/>
                                </div>

                                <div className={defContinua} onClick={async () => {
                                    salvaModifiche("allarmiCaduta");
                                    await getAllarmiCaduta()
                                }}>
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} className="icon-popup" alt='arrow'/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={async () => {
                                    await getAllarmiCaduta();
                                    setMod(0);
                                }}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} className="icon-popup" alt="close"/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("salva")}>
                                    <p>Salva come Profilo</p>
                                    <img src={save} className="icon-popup" alt="save" style={{width: "1.15em"}}/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("carica")}>
                                    <p>Carica Profilo</p>
                                    <img src={carica} className="icon-popup" alt="carica" style={{width: "1.15em"}}/>
                                </div>
                            </>
                        }
                    </div>

                </div>
                <div style={{ display: status === 6 ? "" : "none"}}>
                    <table id="TitoliLista">
                        <tbody>
                        <tr>
                            <td width="15%" style={{border: 0}}>{langSet.descrizione}</td>
                            <td width="20%" style={{border: 0}}>{langSet.daA}</td>
                            <td width="12.5%" style={{border: 0}}>Stanza Libera
                            </td>
                            <td width="62.5%" style={{border: 0}}></td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={{overflow: "scroll", minHeight: "20em", maxHeight: "25em"}}>
                        {allarmiassenzastanza.length > 0 ?
                            allarmiassenzastanza.map((allarme, i) => (
                                <table id="Lista" key={i}>
                                    {mod === 0 ?
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%"
                                                style={{padding: 0, paddingLeft: ".5em"}}>{allarme.descrizione}</td>
                                            <td width="20%" style={{
                                                padding: 0,
                                                paddingLeft: ".5em"
                                            }}>{`${allarme.inizio} - ${allarme.fine}`}</td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {/*{allarme.stato}*/}
                                                <Switch
                                                    checked={allarme.stato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                    disabled
                                                />
                                            </td>
                                            <td width="62.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                            </td>
                                        </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                        <tr key={allarme}>
                                            <td width="15%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id ? allarme.descrizione :
                                                    <input type="text" placeholder={langSet.inserisciDescrizione}
                                                           onChange={(e) => {
                                                               allarmiassenzastanza[i].descrizione = e.target.value;
                                                               setallarmiassenzastanza([...allarmiassenzastanza])
                                                           }} style={{width: "85%"}}/>}
                                            </td>
                                            <td width="20%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                {allarme.id ? <>{allarme.inizio} - {allarme.fine}</> : <><input
                                                    type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                    allarmiassenzastanza[i].inizio = e.target.value;
                                                    setallarmiassenzastanza([...allarmiassenzastanza])
                                                }}/>&ensp;
                                                    <input type="time" style={{width: "5.25em"}} onChange={(e) => {
                                                        allarmiassenzastanza[i].fine = e.target.value;
                                                        setallarmiassenzastanza([...allarmiassenzastanza])
                                                    }}/></>}
                                            </td>
                                            <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                <select value={allarme.stato}
                                                        style={{width: "95%", padding: 0, display: 'none'}}
                                                        onChange={(e) => {
                                                            if (allarme.id) {
                                                                allarmiassenzastanzaSwitch(allarme.id, 'edit', e.target.value);
                                                            }
                                                            allarmiassenzastanza[i].stato = e.target.value;
                                                            setallarmiassenzastanza([...allarmiassenzastanza])
                                                        }} required>
                                                    <option value="0" disabled>- - -</option>
                                                    {selettoreAllarmiassenzastanza.map((currSel, index) => <option
                                                        key={index} value={currSel}>{currSel}</option>)}
                                                </select>


                                                <Switch
                                                    onChange={(checked) => {
                                                        const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                        if (allarme.id) {
                                                            allarmiassenzastanzaSwitch(allarme.id, 'edit', newValue);
                                                        }
                                                        allarmiassenzastanza[i].stato = newValue;
                                                        setallarmiassenzastanza([...allarmiassenzastanza]);
                                                    }}
                                                    checked={allarme.stato === "Allarme immediato"}
                                                    onColor="#86d3ff"
                                                    offColor="#cccccc"
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={24}
                                                    width={48}
                                                />


                                            </td>


                                            <td width="62.5%" style={{padding: 0, paddingLeft: ".5em"}}>


                                                {/*<div className="selettore selettoreFlex selettoreBorder"*/}
                                                {/*     onClick={() => allarmiCadutaSwitch(allarme.id, 'del')}>*/}
                                                {/*    <p>{langSet.elimina} FUNZIONANTE</p>*/}
                                                {/*    <img src={delet} alt="delete"/>*/}
                                                {/*</div>*/}


                                                <div className="selettore selettoreFlex selettoreBorder"
                                                    // onClick={() => allarmiassenzastanzaSwitch(allarme.id, 'del')}
                                                     onClick={() => {
                                                         // allarmiassenzastanzaSwitch(allarme.id, 'del');

                                                         // setallarmiassenzastanza([...allarmiassenzastanza]);
                                                         // allarme.stato = "Nessun allarme";
                                                         // allarmiassenzastanzaSwitch(allarme.id, 'del');


                                                         setallarmiassenzastanza([...allarmiassenzastanza]);
                                                         allarme.stato = "Nessun allarme";

                                                         setTimeout(() => {
                                                             allarmiassenzastanzaSwitch(allarme.id, 'del');
                                                         }, 1000);

                                                         // setTimeout(allarmiassenzastanzaSwitch(allarme.id, 'del'), 1000);

                                                     }}
                                                >
                                                    <p>{langSet.elimina}</p>
                                                    <img src={delet} className="icon-popup" alt="delete"/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    }
                                </table>
                            )) :
                            <p style={{textAlign: "center", margin: "1em"}}>Nessuna Allerta Impostata</p>
                        }
                    </div>
                    {status === 6 ? profilopopup : null}
                    <div className="navigators">
                        {mod === 0 ?
                            <div
                                className="selettore selettoreFlex selettoreViolet"
                                onClick={() => setMod(1)}
                                style={{display: accountData.tipo === 4 ? "none" : null}}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup"/>
                            </div>
                            : <>
                                <div className="selettore selettoreFlex selettorePurple normPosition"
                                     onClick={() => allarmiassenzastanzaSwitch('', 'add')}>
                                    <p>{langSet.aggiungi}</p>
                                    <img src={addW} className="icon-popup" alt="add"/>
                                </div>
                                <div className={defContinua} onClick={() => salvaModifiche("allarmipresenzaStanza")}>
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} className="icon-popup" alt='arrow'/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={async () => {
                                    await getallarmiassenzastanza();
                                    setMod(0);
                                }}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} className="icon-popup" alt="close"/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("salva")}>
                                    <p>Salva come Profilo</p>
                                    <img src={save} alt="save" className="icon-popup" style={{width: "1.15em"}}/>
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder"
                                     onClick={() => openProfiloPopup("carica")}>
                                    <p>Carica Profilo</p>
                                    <img src={carica} alt="carica" className="icon-popup" style={{width: "1.15em"}}/>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </>
    );
}

export default SchedaResidente;