import React, { useEffect, useState } from "react";
import Alert from "../imgs/icons/Alert.svg";
import busybed from "../imgs/icons/Busybed.svg";
import falldetected from "../imgs/icons/falldetected.svg";
import emptyroom from "../imgs/icons/emmptyroom.svg";
import offlinebedroom from "../imgs/icons/offlinebedroom.svg";
import busybathroom from "../imgs/icons/busybathroom.svg";
import emptybathroom from "../imgs/icons/emptybathroom.svg";
import notifiche from "node/notifiche/notifiche";
import offlinebathroom from "../imgs/icons/offlinebathroom.svg";




const Stanza = (props) => {
  const accountData = JSON.parse(localStorage.getItem("account"));

  const getimgState = (residente) => {
    let sensoreLetto = residente.sensori.find((item) => item.tipo === "Sensore letto") || { tipo: "Sensore letto", stato: "Assente", banner: -1 };
    let sensoreCaduta = residente.sensori.find((item) => item.tipo === "Sensore caduta") || { tipo: "Sensore caduta", stato: "Offline", banner: -1 };
    let sensorepresenzastanza = residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") || { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 };
    if (residente.sensori.every((item) => item.stato == "Offline")) return offlinebedroom;
    if (sensoreCaduta.banner == 2) return falldetected;
    if (sensoreCaduta.stato === "Caduta rilevata!") return falldetected;
    if (sensoreLetto.stato === "Presente") return busybed;
    if (sensoreLetto.stato === "Assente" && sensorepresenzastanza.stato === "Presente") return emptyroom;
    if (sensoreLetto.stato === "Assente") return emptyroom;
    if (sensorepresenzastanza.stato === "Presente") return busybed;

    return offlinebedroom;
  };

  const getSensoriAmbiente = (sensori_ambiente) => {
    let sensoreAmbienteCaduta = sensori_ambiente.find((item) => item.tipo === "Sensore caduta") || { tipo: "Sensore caduta", stato: "Offline", banner: -1 };
    let sensoreAmbientepresenza = sensori_ambiente.find((item) => item.tipo === "Sensore presenza stanza") || { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 };
    if (sensori_ambiente.every((item) => item.stato == "Offline")) return offlinebathroom;
    if (sensoreAmbienteCaduta.stato === "Caduta rilevata!") return falldetected;
    if (sensoreAmbientepresenza.stato === "Presente") return busybathroom;
    if (sensoreAmbientepresenza.stato === "Assente") return emptybathroom;

    return offlinebathroom;
  };

  const getNotificheNonConfermate = async (token, id_residente, id_stanza) => {
    props.loading(true);

    // console.log("tetteteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");

    let notificheNonConfermate = [];

    if (id_residente !== undefined) {
      await notifiche.getNotifiche(token).then((res) => {
        for (let notifica of res.data) {
          if (notifica.id_residente === id_residente && !notifica.confermata) {
            notificheNonConfermate.push(notifica);
          }
        }
      });
      props.loading(false);
    } else {
      await notifiche.getNotifiche(token).then((res) => {
        for (let notifica of res.data) {
          if (notifica.id_stanza === id_stanza && !notifica.confermata && notifica.id_residente === undefined) {
            notificheNonConfermate.push(notifica);
          }
        }
      });
      props.loading(false);
    }
    return notificheNonConfermate;
  };

  return (

    

    <div key={"top" + props.stanza.id_stanza} id={props.banner ? "GrigliaAlarm" : "Griglia"}>

      { console.log("test") }
      { console.log(props.stanza) }

      <table style={{ marginTop: "3%" }}>
        <tbody >
          <tr>
            <td style={{ textAlign: "center", color: "black", fontWeight: "800", fontSize: "32px", fontFamily: 'Titillium Web' }}>
              {props.banner ? (
                <div className="lamp-effect" style={{ display: "flex", justifyContent: "right", position: "absolute", top: "2%", right: "5%" }}>
                  <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                </div>
              ) : (
                <div style={{ width: "40px" }}></div>
              )}
              {props.numeroStanza}
            </td>
          </tr>
        </tbody>
      </table>

      <div key={"content" + props.stanza.id_stanza + props.stanza.tipo} style={{ display: "flex", width: props.letti === 1 ? "76px" : "152px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "20px", height: "40px", justifyContent: "", marginTop: "20px" }}>
        {props.stanza.data.sort((a, b) =>
        // ordina in base al ultimo carattere dell'id_residente
          a.id_residente.charAt(a.id_residente.length - 1) > b.id_residente.charAt(b.id_residente.length - 1) ? 1 : -1
          
        ).map((residente, index) => {
          
          return (

            <React.Fragment key={residente.id_residente + "container"}>
              {index <= 1 ? (
                <>
                  <div key={residente.id_residente + "bedName"} style={{ padding: "7px" }}>
                    {residente.id_residente.charAt(residente.id_residente.length - 1)}
                  </div>
                  <div key={residente.id_residente + "imgbox"} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}
                       onClick={ residente.sensori.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, residente.id_residente)) : null }
                  >

                    {index === 0 || 2 ? (
                      <div key={residente.id_residente + "img"} style={{ width: "20px", borderRadius: "5px 20px 5px 20px", padding: "15px", border: residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") && residente.sensori.find((item) => item.tipo === "Sensore presenza stanza").stato === "Presente" || residente.sensori.find((item) => item.tipo === "Sensore letto") && residente.sensori.find((item) => item.tipo === "Sensore letto").stato === "Presente" ? "4px solid #1DAFED" : ".5px solid #CDDEFF", backgroundColor: residente.sensori.some((item) => item.banner !== 0) ? "#FBE6E5" : "#FFFFFF", color: "black", fontWeight: "600", fontSize: "1.15em", justifyContent: "center", display: "flex", height: "10px", alignItems: "center" }}>
                        <img src={getimgState(residente)} style={{ width: "60px", height: "60px", cursor: residente.sensori.some((item) => item.banner !== 0) ? "pointer" : "default" }} />
                        {/* <h1>tet</h1> */}
                      </div>
                    ) : (
                      <div key={residente.id_residente + "null"}></div>
                    )}

                  </div>
                </>
              ) : null
            }
            </React.Fragment>
          );
        })}
      </div>

      {props.letti > 2 ? (
        <div style={{ display: "flex", width: props.letti === 1 ? "76px" : "152px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "1.15em", marginTop: "30px", height: "40px", justifyContent: "center" }}>
          {props.stanza.data.map((residente, index) => {
            
            return (
              <React.Fragment key={residente.id_residente}>
                {index > 1 ? (
                  <>
                    <div key={residente.id_residente + "bedName"} style={{ padding: "10px" }}>
                      {index}
                    </div>
                    <div key={index.id_residente + "imgbox"} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                      {index === 0 || 2 ? (
                        <div key={residente.id_residente} style={{
                          width: "20px",
                          borderRadius: "5px 20px 5px 20px",
                          padding: "15px",
                          borderLeft: ".5px solid #CDDEFF",
                          borderRight: index === 1 || index === 3 || props.letti === 1 && index === 0 ? "none" : ".5px solid #CDDEFF",
                          borderTop: ".5px solid #CDDEFF",
                          borderBottom: ".5px solid #CDDEFF",
                          color: "black",
                          fontWeight: "600",
                          fontSize: "1.15em",
                          justifyContent: "center",
                          display: "flex",
                          height: "10px",
                          alignItems: "center",
                        }}>
                          {/*{ props.letti } { index }*/}
                          <img src={getimgState(residente)} style={{ width: "40px", height: "40px" }} />
                        </div>
                      ) : (
                        <div key={residente.id_residente + "null"}></div>
                        )}
                    </div>
                  </>
                ) : null
              }
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      {/* { console.log("test12") }
      { console.log('cisao',props.stanza.data[0].sensori_ambiente[0]) }
      { console.log(props.stanza.data[0]) } */}

      {props.stanza.data[0].sensori_ambiente[0] !== undefined ? (

        <div style={{ display: "flex", width: "76px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "1.15em", marginTop: "30px", height: "40px", justifyContent: "center" }}
             onClick={ props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, undefined, props.stanza.data[0].id_stanza)) : null }>

          <div style={{ padding: "13px" }}></div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
            {/* ddddddd{ props.stanza.data[0].sensori_ambiente[0] !== undefined } */}
            {/* <div> props.stanza { props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) } </div> */}
            <div style={{
              width: "20px",
              borderRadius: "5px 20px 5px 20px",
              padding: "15px",
              borderLeft: ".5px solid #CDDEFF",
              // borderRight: ".5px solid #CDDEFF",
              borderTop: ".5px solid #CDDEFF",
              backgroundColor: props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) ? "#FBE6E5" : "#FFFFFF",
              borderRight: props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) ? "none" : ".5px solid #CDDEFF",
              borderBottom: ".5px solid #CDDEFF",
              color: "black",
              fontWeight: "600",
              fontSize: "1.15em",
              justifyContent: "center",
              display: "flex",
              height: "10px",
              alignItems: "center",
            }}>
              <img src={getSensoriAmbiente(props.stanza.data[0].sensori_ambiente)} style={{ width: "40px", height: "40px", cursor: props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) ? "pointer" : "default" }}  />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Stanza;
